.cursor {
  position: absolute;
  background: $light-gray;
  opacity: 0;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  transform-origin: 0 0;
  z-index: 30;
  transition:
    transform 0.35s,
    opacity 0.35s 0.25s;
  user-select: none;
  pointer-events: none;
  transform: scale(0) translate(-50%, -50%);

  &.color--petrol {
    background: $salmon;
    opacity: 1;
  }

  &.color--orange {
    background: $light-sea-green;
    opacity: 1;
  }

  //mix-blend-mode: exclusion;

  //&.active-image {
  //  opacity: 1;
  //
  //  transform: scale(30);
  //  mix-blend-mode: multiply;
  //  svg {
  //    transform: scale(0.2) translate(15%, -135%);
  //    mix-blend-mode: difference;
  //    transform-origin: center;
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //  }
  //
  //  &:before {
  //    opacity: 1;
  //  }
  //}

  .cursor--arrow svg {
    transform: scale(0) translate(15%, -135%);
    mix-blend-mode: difference;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .cursor--eye svg {
    display: none;
  }

  //&:before {
  //  content: "";
  //  width: 100%;
  //  height: 100%;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  display: block;
  //  background-color: $white;
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-size: cover;
  //  border-radius: 100%;
  //  opacity: 0;
  //}

  &.active {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);

    .cursor--eye svg {
      display: none;
    }

    .cursor--arrow svg {
      transform: scale(0.2) translate(15%, -135%);
      mix-blend-mode: difference;
      transform-origin: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      opacity: 1;
    }
  }

  &.active-triangle {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    border-radius: 0;
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);

    .cursor--eye svg {
      display: none;
    }

    .cursor--arrow svg {
      transform: scale(0.2) translate(50%, -135%);
      mix-blend-mode: difference;
      transform-origin: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      opacity: 1;
    }
  }

  &.active-rhombus {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    border-radius: 0;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    .cursor--eye svg {
      display: none;
    }

    .cursor--arrow svg {
      transform: scale(0.2) translate(15%, -135%);
      mix-blend-mode: difference;
      transform-origin: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      opacity: 1;
    }
  }

  &.active-video {
    mix-blend-mode: unset;
    background: none;
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);

    .cursor--eye svg {
      transform: scale(1);
      mix-blend-mode: exclusion;
      transform-origin: center;
      position: absolute;
      top: 0;
      left: 0;
      display: block;

      opacity: 1;

      #eye {
        animation: squeeze 2s infinite;
      }
      #lid {
        animation: blink 2s infinite;
      }
    }
  }

  &.cantons {
    width: 50px;
    height: 25px;
    border-radius: 0;
    transform: scale(0) translate(0, -50%);
  }

  &.active-cantons {
    opacity: 1;
    transform: scale(1) translate(0, -150%);
    display: inline-block;
    padding: 3px 8px;
    border-radius: 0;
    height: auto;
    width: auto;
    color: white;
    cursor: pointer;

    .cursor--eye svg {
      display: none;
      opacity: 0;
      transform: scale(0);
    }

    .cursor--arrow svg {
      display: none;
      opacity: 0;
      transform: scale(0);
    }
  }
}

;@import "sass-embedded-legacy-load-done:131";