$grid-gap: rem-calc(70px);
$grid-gap-small: rem-calc(32px);
$teaser-height: 26em;

.masonry-grid,
.teasers {
  display: flex;
  width: 100%;
  //margin-bottom: $general-margin;
  flex-wrap: wrap;
}

ul.masonry-grid {
  padding-left: 0;
  list-style: none;
}

.masonry-grid > .teaser {
  flex: 1 1 100%;
  position: relative;
  //transition: $general-transitions;
  //background-color: $article-block-color;

  height: fit-content;

  margin-bottom: $grid-gap-small;
  width: 100%;

  &[data-interaction-type="clipPath"] {
    z-index: 1001;
  }

  &:first-child:not(.card) {
    @include breakpoint(medium) {
      grid-row-start: 2;
    }
  }

  @include breakpoint(medium) {
    margin-right: $grid-gap-small;
    flex: 1 1 40%;
    width: auto;
  }
}

.masonry-grid > .video-embed {
  flex: 1 1 100%;
  position: relative;
  margin-bottom: $grid-gap-small;

  // &:first-child {
  //   grid-row-start: 1;
  // }
  // &:nth-child(2) {
  //   grid-row-start: 2;
  // }

  @include breakpoint(medium) {
    margin-right: $grid-gap-small;
    flex: 1 1 40%;
    width: auto;
  }
}

.teaser__content--description {
  //color: $white;
  z-index: 10;

  @include breakpoint(large) {
    font-size: 1.2rem;
  }
}

.teasers > .teaser {
  flex: 1 1 100%;
  margin-bottom: $grid-gap;
  margin-right: 0;
}

.teaser__wrapper {
  //padding-top: 10em;
  //padding: $grid-gap-small;

  //margin-top: -2.65rem;
  //z-index: 1000;

  .teasers & {
    height: 100%;
  }
}

.teaser__image-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  //background: $salmon;
  z-index: 1000;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    //@include breakpoint(medium) {
    clip-path: circle(130px at var(--clip-position));

    //}
    @include breakpoint(small only) {
      transition: clip-path 0.35s 0.15s;
      clip-path: circle(var(--clip-size) at var(--clip-position));
    }
  }
}

.teaser__image--grayscale {
  opacity: 0.15;
  filter: grayscale(100%);
}

.clipped-image {
  background-color: rgba(0, 0, 0, 0.1);
}

.teaser__item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  @extend %page-theme-color;

  min-height: $teaser-height * 0.7;
  @include breakpoint(medium) {
    min-height: $teaser-height * 0.8;
  }
  @include breakpoint(large) {
    min-height: $teaser-height;
  }

  .teasers & {
    height: 100%;
  }

  .teaser--large & {
    @include breakpoint(large) {
      flex-direction: row-reverse;
    }
  }
}

.teaser__content,
.card--content {
  position: relative;
  padding: $grid-gap-small $grid-gap-small * 1.5 0 $grid-gap-small * 0.75;

  @include breakpoint(medium) {
    padding: $grid-gap-small $grid-gap-small * 1.5 0.5rem $grid-gap-small;
  }

  z-index: 1001;

  [data-interaction-type="clipPath"] & {
    z-index: 1001;
  }
}

.teaser__content--title {
  position: relative;
  z-index: 100;
}

// For Modern Browser with CSS Grid Support
@supports (grid-area: auto) {
  .masonry-grid:not(.masonry-grid--grid) {
    @include breakpoint(medium) {
      display: grid;
      grid-gap: $grid-gap;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      grid-auto-rows: $grid-gap;
    }

    &.masonry-grid--small {
      grid-gap: $grid-gap-small;
      grid-auto-rows: $grid-gap-small;
    }
    &.masonry-grid--large {
      grid-auto-rows: $grid-gap * 1.5;
    }

    .teaser,
    .card {
      margin-right: 0;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:130";