/* Libre Caslon */
/* regular */
@font-face {
  font-family: "LibreCaslonText";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/LibreCaslonText-Regular.woff2) format("woff2");
}

/* italic */
@font-face {
  font-family: "LibreCaslonText";
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/LibreCaslonText-Italic.woff2) format("woff2");
}

/* bold */
@font-face {
  font-family: "LibreCaslonText";
  font-style: normal;
  font-weight: 800;
  src: url(./fonts/LibreCaslonText-Bold.woff2) format("woff2");
}

/* Futura */

/* book */
@font-face {
  font-family: "FuturaPT";
  font-style: normal;
  font-weight: 350;
  src: url(./fonts/futura-pt_book.woff2) format("woff2");
}

/* medium */
@font-face {
  font-family: "FuturaPT";
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/futura-pt_medium.woff2) format("woff2");
}

/* demi */
@font-face {
  font-family: "FuturaPT";
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/futura-pt_demi.woff2) format("woff2");
}

/* bold */
@font-face {
  font-family: "FuturaPT";
  font-style: normal;
  font-weight: 800;
  src: url(./fonts/futura-pt_bold.woff2) format("woff2");
}

/* NotoSerifThai */

@font-face {
  font-family: "NotoSerifThai";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/NotoSerifThai-VariableFont_wdth,wght.woff2) format("woff2");
}

;@import "sass-embedded-legacy-load-done:1";