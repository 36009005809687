.footer {
  background-color: $astronaut;
  padding: 10vh 0 2vh 0;

  @include breakpoint(medium) {
    padding: 10rem 0 4rem 0;
  }
}

.footer__element {
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.footer__wrapper {
  position: relative;
  //height: 100%;
  //background: transparent linear-gradient(180deg, #3D8062 0%, #13322D 100%) 0% 0% no-repeat padding-box;
  z-index: 10;
  padding: 10rem 0 0 0;

  @include breakpoint(large) {
    padding: 12rem 0 4rem 0;
  }
}

.footer--social {
  display: flex;
  align-items: center;
  align-content: center;

  .facebook {
    transform: translate(0, 20%);
  }
}

.footer__meta {
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;

  @include breakpoint(medium) {
    margin-top: 2rem;
  }

  li {
    list-style: none;
    a {
      color: $white;
    }
  }
}

.footer--meta-container {
  margin-top: 4rem;

  @include breakpoint(medium) {
    border-top: 2px solid $white6;
  }
}

.footer--cta-link {
  border-bottom: 2px solid $white6;
  padding-bottom: 2rem;
  padding-top: 2rem;
  @include breakpoint(medium) {
    border-bottom: none;
  }
}

.footer--cta-link--wrapper {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    height: 100%;
    p {
      margin-bottom: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:124";