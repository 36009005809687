.center-list {
  border-top: 1px solid $medium-gray;
  padding-top: $general-margin;
}

.masonry-grid > .card {
  position: relative;
  margin-bottom: $grid-gap-small;
  margin-right: $grid-gap-small;

  @include breakpoint(medium) {
    flex: 1 1 40%;
  }
}

.card {
  background-color: $white;
  height: fit-content;
  width: 100%;
  @include breakpoint(medium) {
    width: auto;
  }

  &.card--download {
    margin: $general-block-margin 0;

    @extend %page-theme-color;
  }
}

.card--content {
  //height: 100%;
  //min-height: 20rem;
  @include breakpoint(medium) {
    max-width: 80%;
  }
}

.card--button-container {
  margin-bottom: 2rem;
}

.card--category {
  margin-bottom: 0.5rem;
  display: block;
}

.center-list--category-headline {
  margin-bottom: $general-block-margin * 2;
}

$cantons: "aargau", "appenzell-innerrhoden", "appenzell-ausserrhoden",
  "basel-landschaft", "basel-stadt", "bern", "genf", "glarus", "graubnden",
  "jura", "luzern", "neuenburg", "nidwalden", "obwalden", "schaffhausen",
  "schwyz", "solothurn", "st-gallen", "tessin", "thurgau", "uri", "wallis",
  "waadt", "freiburg", "zug", "liechtenstein", "zrich";

@mixin active-canton {
  @each $canton in $cantons {
    &.#{$canton} .#{$canton} {
      fill: $light-sea-green;
      stroke-width: 0;
    }
  }
}

.center-list--map {
  @include active-canton();

  .bg {
    transition: fill 0.5s linear;

    &:hover {
      fill: $light-sea-green;
      cursor: pointer;
    }
  }

  &.all-cantons {
    .bg {
      //fill:#33AE9F;
      stroke: #ffffff;
      stroke-width: 1;
    }
  }
}

.center-list--filter-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3.125rem;

  label {
    text-align: center;
    display: block;
  }
}

;@import "sass-embedded-legacy-load-done:133";