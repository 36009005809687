$cover-height: 6rem;
$cover-width: $cover-height * 0.8;

.moodboard {
  position: relative;
  //height: 100vh;
  width: 100%;
  color: $white;

  .page--color-orange & {
    background-color: $salmon;
  }

  .page--color-petrol & {
    background-color: $light-sea-green;
  }

  .page--color-blue & {
    background-color: $astronaut;
  }
}

.moodboard__cover {
  padding-top: 15vh;
  padding-bottom: 15vh;

  @include breakpoint(medium) {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }

  .html--debug & {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 100%;
      height: 0.1rem;
      background-color: $mahogany;
      transform: translate(0, -50%);
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      width: 0.1rem;
      transform: translate(-50%, 0);
      background-color: $mahogany;
    }
  }
}

.moodboard__cover--container {
  height: 100%;
}

.moodboard__cover--inner-wrapper {
  height: 100%;

  @include breakpoint(small only) {
    display: flex;
    align-items: center;
  }

  @include breakpoint(medium) {
    margin-left: 2.5em;
  }

  @include breakpoint(large) {
    margin-left: 0;
  }
}

.moodboard__cover--top,
.moodboard__cover--bottom,
.moodboard__cover--left,
.moodboard__cover--right {
  .html--debug & {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.moodboard__cover--top {
  position: absolute;
  left: 0;
  top: 0;
  height: $cover-height;
  width: 100%;
}

.moodboard__cover--bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: $cover-height;
  width: 100%;
}

.moodboard__cover--left {
  position: absolute;
  left: 0;
  top: 0;
  width: $cover-width;
  height: 100%;
}

.moodboard__cover--right {
  position: absolute;
  right: 0;
  top: 0;
  width: $cover-width;
  height: 100%;
}

.moodboard__content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 2rem;

  .html--debug & {
    border: 1px solid $astronaut;
  }
}

.moodboard__content--text {
  position: relative;
  margin-top: 100px;
  margin-bottom: 2rem;

  p {
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    padding-left: 2rem;

    &:after {
      content: "";
      position: absolute;
      top: -1rem;
      width: 60px;
      height: $global-line-with;
      background-color: $black;
    }
  }
}

.moodboard__content--anchors {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
    padding-left: 2rem;
  }

  a {
    margin-bottom: $general-block-margin;
    @include breakpoint(medium) {
      margin-right: $general-block-margin;
      margin-bottom: 0;
    }

    &:hover {
      opacity: 1 !important;
    }
  }
}

.moodboard__content--left {
  z-index: 100;
}

.moodboard__content--right {
}

.moodboard__image {
  //position: absolute;
  //height: 100%;
  //width: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include breakpoint(small only) {
    margin-bottom: 2.5rem;
  }
}

.moodboard__image--container {
  //right: 20vw;
  overflow: hidden;
  transform-origin: center;
  height: 100%;

  &.moodboard__image--fourth,
  &.moodboard__image--fifth,
  &.moodboard__image--ninth,
  &.moodboard__image--tenth,
  &.moodboard__image--eleventh,
  &.moodboard__image--twelfth {
    max-width: 900px;

    img {
      top: 20%;
      bottom: 0;
      transform: translateY(0);
      background-position: bottom center;
    }
  }

  @include breakpoint(small only) {
    width: 240px;
    height: 240px;
    margin: 0 auto;
    clip-path: inset(0 0 0 0 round 50%);
    border-radius: 50%;
  }

  @include breakpoint(medium) {
    position: absolute;
    right: calc(2 * (3.5vw + 2vh - 1vmin));
    top: 0;
    max-width: 450px;
  }

  @include breakpoint(large) {
    right: calc(4 * (3.5vw + 2vh - 1vmin));
  }

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;

    @include breakpoint(medium) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include breakpoint(small only) {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .html--debug & {
    background-color: rgba(69, 80, 124, 0.4);
  }
}

@keyframes appear-form {
  0% {
    transform: translate(-50%, -50%) scale(0.5) rotate(12deg);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.3) rotate(12deg);
    opacity: 1;
  }
}

// Forms

.parallax-scene-container {
  //width: 100vw;
  //height: 100vh;
  position: absolute;
  top: 40%;
  left: 40%;
  opacity: 0;
  transition:
    transform 0.35s,
    opacity 0.35s;
  transform: translate(-50%, -50%) scale(0) rotate(12deg);
  animation: appear-form 0.6s 0.8s forwards ease-in-out;
  transform-origin: center center;
  @include breakpoint(large) {
    left: 50%;
  }
}

.parallax-scene {
  img {
    position: absolute;
  }

  .parallax--above {
  }
}

.parallax-scene-image {
  //width: 100vw;
  //height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  transition:
    transform 0.35s,
    opacity 0.35s;
  transform-origin: center center;
}

.moodboard__form {
  position: absolute;
  width: 60vh;
  height: 60vh;
  background-color: rgba(255, 255, 255, 0.2);
  //transform: translate(-50%, -50%) scale(0) rotate(12deg);
  //top: 0%;
  //left: 50%;
  clip-path: circle(50% at 50% 50%);

  &.moodboard__form--triangle {
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);
  }

  &.moodboard__form--rhombus {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }

  &.moodboard__form--circle {
    clip-path: circle(50% at 50% 50%);
  }

  &.parallax--beneath {
    //top: 30%;
    //left: 50%;
  }
}

;@import "sass-embedded-legacy-load-done:128";