$social: "facebook", "twitter", "youtube", "instagram", "whatsapp",
  "twitter-white", "twitter-black", "arrow-black-right", "arrow_black",
  "arrow-right-yellow", "twitter-green", "facebook-green", "next", "prev",
  "mail-green";

$icon-size: 40px;
$icon-size-small: 25px;

@mixin social-icons {
  &.social {
    background-repeat: no-repeat;
    transition: all 0.2s ease;

    @each $icon in $social {
      &.#{$icon} {
        background-image: url("img/icons/#{$icon}.svg");
      }
    }

    &:hover {
      opacity: 0.7;
      transition: all 0.2s ease;
    }
  }
}

.icon {
  display: inline-block;
  font-size: 0;
  width: $icon-size;
  height: $icon-size;
  margin: 0 auto;
  background-size: cover;
  background: no-repeat 50% 50%;
  margin-right: $general-block-margin * 0.5;

  &.icon--small {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &.icon--tiny {
    height: $icon-size-small * 0.5;
    width: $icon-size-small * 0.5;
  }

  .link--next & {
    margin-left: $general-block-margin * 0.5;
    margin-right: 0;
  }

  &.black {
    border-radius: 40px;
  }
}

.icon-large {
  width: 50px;
  height: 50px;
}

.social {
  @include social-icons();
}

.icon--small {
  svg {
    height: $icon-size-small * 0.5;
    width: $icon-size-small * 0.5;
  }
}

$li-icons: "pdf", "link", "video", "audio";
.icon-pill {
  @each $icon in $li-icons {
    &--#{$icon} {
      display: block;
      position: relative;
      margin-left: 3rem;
      &::before {
        position: absolute;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("img/icons/#{$icon}.svg");
        content: " ";
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;

        top: calc(50% - 1rem);
        left: -3rem;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:120";