.selftest--container {
  //padding: 0 10vw;
  display: flex;
  position: relative;
  flex-direction: column;
  @include breakpoint(large) {
    flex-direction: row;
  }
}

.selftest--wrapper {
  position: relative;
  @extend %page-theme-color;
  flex: 1 1 70%;
}

.selftest--message-container {
  position: relative;
  @extend %page-theme-color-20;
  //flex: 1 1 30%;

  flex: 0;

  transition: all 400ms ease;

  &.active {
    flex: 1 1 40%;
  }
}

.selftest--message-wrapper {
  height: 100%;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  transition: opacity 400ms ease;

  padding: 4rem 0;

  @include breakpoint(large) {
    position: absolute;
    overflow-y: scroll;
  }

  .active & {
    animation: fadeIn 2s ease;
  }
}

.selftest--message {
  padding: 2rem;

  @include breakpoint(large) {
    padding: 0 3rem;
  }
  //&:first-child {
  //  display: block!important;
  //}
}

.selftest--question {
}

.selftest--question-start {
  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .test--started & {
    display: none;
  }
}

.selftest--question-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include breakpoint(medium) {
    height: 80vh;
  }
  .message--open & {
    @include breakpoint(medium down) {
      height: auto;
    }
  }
}

.selftest--question-wrapper {
  margin-top: 10vh;
  margin-bottom: 10vh;

  @include breakpoint(medium) {
    margin-top: 30vh;
    margin-bottom: 0;
  }

  .message--open & {
    @include breakpoint(medium down) {
      margin-top: 10vh;
      margin-bottom: 5vh;
    }
  }
}

.button--yes,
.button--no {
  opacity: 0.7;
}

.button--yes {
  [data-answer="yes"] & {
    opacity: 1;
  }
}

.button--no {
  [data-answer="no"] & {
    opacity: 1;
  }
}

.selftest--question-navigation {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  justify-content: space-between;
  display: none;

  margin-bottom: 3rem;

  .test--started & {
    display: flex;
  }

  //a {
  //  display: block;
  //}
}

.selftest--progress-text {
  display: none;

  .test--started & {
    display: block;
  }
}

.selftest--questions {
  transition: max-width 400ms ease;

  @include breakpoint(medium) {
    max-width: 60%;
  }

  .message--open & {
    max-width: 100%;
  }
}

;@import "sass-embedded-legacy-load-done:136";