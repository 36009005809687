$video-height: rem-calc(480px);

.video-embed {
  margin-bottom: $general-block-margin * 1.5;
  height: fit-content;
  pointer-events: all;
  position: relative;
  display: block;

  @include breakpoint(medium) {
    cursor: none;
  }
}

.video-embed--still {
  pointer-events: none;
  background-position: center;
  background-size: cover;
  position: relative;

  height: $video-height * 0.7;
  @include breakpoint(medium) {
    height: $video-height * 0.8;
  }
  @include breakpoint(large) {
    height: $video-height;
  }

  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
  }
}

.video-embed--footer {
  margin-top: $general-block-margin * 1.5;
  text-align: center;
}

.video-embed--overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -80%);
  margin-top: 1rem;

  pointer-events: none;

  span {
    text-align: center;
    display: block;
    margin-top: 1rem;
  }

  svg {
    height: 8rem;
    width: 8rem;
    display: block;

    #eye_02 {
      animation: squeeze 2s infinite;
    }
    #lid_02 {
      animation: blink 2s infinite;
    }
  }
}

;@import "sass-embedded-legacy-load-done:134";