.breadcrumbs--container {
  position: absolute;
  top: 50%;
  z-index: 1000;
  left: 50px;
  transform: rotate(90deg) translateX(-50%);
  transform-origin: left;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }

  .page--basic-layout & {
    display: block;
    position: relative;
    top: auto;
    z-index: 10;
    left: auto;
    transform: none;
  }
}

.breadcrumbs--list {
  display: flex;
}

.breadcrumbs--divider {
  margin-right: 1rem;
}

.breadcrumbs--element {
  margin-right: 1rem;

  &.breadcrumbs--element--active {
    color: $mahogany;
  }
}

;@import "sass-embedded-legacy-load-done:127";