.slider-container {
  padding-inline: 0;
  @include breakpoint(medium) {
    padding-inline: 2rem;
  }
}

.sliders {
  display: flex;
  gap: 1rem;
  flex-flow: column;
  @include breakpoint(large) {
    flex-direction: row;
  }
}

.sliders__question-slider {
  flex-basis: 100%;
  max-width: 100%;
  order: 2;
  @include breakpoint(large) {
    flex-basis: 70%;
    max-width: 70%;
    order: 1;
  }
}

.sliders__news-slider {
  flex-basis: 100%;
  max-width: 100%;
  order: 1;
  @include breakpoint(large) {
    flex-basis: 30%;
    max-width: 30%;
    order: 2;
  }
}

// question Slider
.news-slider,
.question-slider {
  position: relative;
  height: 32.125rem;
}

.question-slider {
  @extend %page-theme-color;
}

.news-slider__container,
.question-slider__container {
  margin-top: $general-margin * 0.5;

  @include breakpoint(medium) {
    margin-top: $general-margin;
  }
}

.news-slider--wrapper,
.question-slider--wrapper {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(medium) {
    padding-top: 4rem;
  }
}

.news-slider__slides,
.question-slider__slides {
  //max-width: 1000px;
  overflow: hidden;
  position: relative;

  height: 100%;
}

.news-slider__slider,
.question-slider__slider {
  display: flex;
  height: 100%;
}

.question-slider__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.news-slider__thumbs,
.question-slider__thumbs {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);

  z-index: 100;

  &.question-slider__thumbs--facts {
    left: 0;
    position: relative;
    transform: none;
    bottom: auto;
    justify-content: flex-start;

    a[data-thumb] {
      &::before {
        background-color: $white;
      }
    }
  }

  a[data-thumb] {
    position: relative;
    height: 32px;
    width: 46px;
    margin: 0;
    padding: 0;
    background: none;
    border: 0 none;
    outline: none;
    cursor: pointer;

    svg {
      fill: none;
      stroke-width: 1;
      stroke-dasharray: 39 80;
      stroke-dashoffset: 39;
      transition: stroke-dashoffset 1s;
      transition: stroke-dasharray 1s;

      shape-rendering: geometricPrecision;
    }

    &::before {
      background-color: $white;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      margin-top: -4px;
      margin-left: -4px;
      transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border-radius: 50%;
      opacity: 1;
    }

    &[data-current] {
      opacity: 1;
      position: relative;
      //transition: all ease-in-out 200ms;

      svg {
        transition: stroke-dashoffset 5s;
        transition: stroke-dasharray 5s;

        .no-interval & {
          transition: stroke-dashoffset 0.8s;
          transition: stroke-dasharray 0.8s;
        }

        //stroke-dashoffset: 0;
        stroke-dasharray: 119 77;
      }

      &::before {
        background-color: $black;
        opacity: 1;
      }
    }
  }
}

.question-slider {
  h2 {
    font-size: 2rem;
  }
}

.news-slider {
  background-color: $salmon;
  h2 {
    font-size: 1.1875rem;
    // font-weight: 500;
    font-weight: normal;
    padding: 2.33rem 2rem 1rem;
    margin-bottom: 0;
    font-family: $font-main;
  }
}
.news-slider--wrapper {
  padding: 1rem 2rem;
  color: $white;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 1.2rem;
}
.news-slider__entry {
  padding-bottom: 1.2rem;
  width: 100%;
  text-align: left;
  h3 {
    // font-weight: 500;
    font-weight: normal;
    font-family: $font-main;
    font-size: 1.3125rem;
    margin-bottom: 0.75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  span {
    // font-weight: 400;
    font-weight: normal;
    margin-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $white;
  }
}

;@import "sass-embedded-legacy-load-done:129";