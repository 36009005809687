$font-main: "FuturaPT", sans-serif;
$font-condensed: "LibreCaslonText", serif;
$font-thai: "NotoSerifThai", serif;
$body-font-size-tiny: rem-calc(14);
$body-font-size-small: rem-calc(15);
$body-font-medium: rem-calc(20);
$body-font-size: rem-calc(18);
$body-font-size-large: rem-calc(25);
$body-line-height: 1.4;

$h1-font-size: rem-calc(74px);
$h1-line-height: 1.3;
$h2-font-size: rem-calc(40px);
$h2-line-height: 1.4;
$h3-font-size: rem-calc(35px);
$h3-line-height: 1.1;
$h4-font-size: rem-calc(28px);
$h4-line-height: 1.1;
$h5-font-size: rem-calc(25px);
$h5-line-height: 1.1;

$regular: 400;
$semi-bold: 500;
$bold: 600;

// $font-weight-main: $semi-bold;

$general-block-margin: 1.5rem;
$general-margin: rem-calc(60px);
$general-padding: 2rem;
$general-border-style: dashed;

%body-font {
  font-family: $font-main;
  // font-weight: $font-weight-main;
  font-weight: normal;
  font-style: normal;
}

%h1-font-size {
  font-size: $h1-font-size * 0.5;
  @include breakpoint(medium) {
    font-size: $h1-font-size * 0.75;
  }
  @include breakpoint(large) {
    font-size: 4.5vw;
  }
  @include breakpoint(xLarge) {
    font-size: $h1-font-size;
  }
}

%h2-font-size {
  font-size: $h2-font-size * 0.6;
  @include breakpoint(medium) {
    font-size: $h2-font-size * 0.8;
  }
  @include breakpoint(large) {
    font-size: $h2-font-size;
  }
}

%h3-font-size {
  font-size: $h3-font-size * 0.65;
  @include breakpoint(medium) {
    font-size: $h3-font-size * 0.8;
  }
  @include breakpoint(large) {
    font-size: $h3-font-size;
  }
}

%h4-font-size {
  font-size: $h4-font-size * 0.7;
  @include breakpoint(medium) {
    font-size: $h4-font-size * 0.8;
  }
  @include breakpoint(large) {
    font-size: $h4-font-size;
  }
}

%h5-font-size {
  font-size: $h5-font-size * 0.9;
  @include breakpoint(medium) {
    font-size: $h5-font-size;
  }
}

%menu-font-styles {
  font-family: $font-condensed;
  // font-weight: $bold;
  font-weight: normal;
  font-size: $body-font-medium;
  text-transform: uppercase;
}

%small-title-font-styles {
  font-family: $font-condensed;
  // font-weight: $bold;
  font-weight: normal;
  font-size: $body-font-medium;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $bold;
  margin-bottom: $general-block-margin;
  font-family: $font-condensed;
  line-height: $body-line-height; /* default fallback */

  .lang--th & {
    font-family: $font-thai !important;
  }
}

h1,
.h1 {
  @extend %h1-font-size;
  line-height: $h1-line-height;

  .plugin__richtext & {
    font-size: $h1-font-size * 0.5;
    @include breakpoint(medium) {
      font-size: $h1-font-size * 0.65;
    }
    //margin-bottom: $general-block-margin * 2;
  }
}

h2,
.h2 {
  @extend %h2-font-size;
  line-height: $h2-line-height;

  .plugin__richtext & {
    font-size: $h2-font-size * 0.61;
    @include breakpoint(medium) {
      font-size: $h2-font-size * 0.8;
    }
    margin-bottom: $general-block-margin * 2;
  }
}

h3 {
  @extend %h3-font-size;
  line-height: $h3-line-height;

  .plugin__richtext & {
    font-size: $h3-font-size * 0.61;
    @include breakpoint(medium) {
      font-size: $h3-font-size * 0.8;
    }
    margin-bottom: $general-block-margin;
  }
}

h4 {
  @extend %h4-font-size;
  line-height: $h4-line-height;

  margin-bottom: $general-block-margin * 0.7;
  .card--download & {
    margin-top: $general-block-margin !important;
  }
}

h5 {
  @extend %h5-font-size;
  line-height: $h5-line-height;
  margin-bottom: $general-block-margin * 0.6;
  margin-top: 0;
}

// to be extended where it is
%indent-list-items {
  margin-left: 1em;
}

body {
  font-family: $font-main;
  font-size: $body-font-size;

  line-height: $body-line-height;
  color: $black;
  // font-weight: $font-weight-main;
  font-weight: normal;
  &.lang--th {
    font-size: $body-font-size * 1.2;
    line-height: $body-line-height * 0.8;
  }
}

p,
form,
table {
  margin-bottom: $general-block-margin;
  .lang--th & {
    font-family: $font-thai !important;
  }
}

ul,
ol {
  margin-bottom: $general-block-margin;
  padding-left: 1rem;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

strong {
  font-weight: $bold;
}

address {
  font-style: normal;
}

.plugin__richtext {
  ul,
  ol {
    margin-left: 1.5em;

    li {
      padding-left: 0.2em;
    }
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a:not(.button) {
    text-decoration: underline;
  }

  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $general-block-margin;
    margin-bottom: $general-block-margin;
    font-family: $font-main;
    line-height: 1.2;
    font-weight: $bold;
    //font-weight: normal;

    @include breakpoint(medium) {
      margin-top: $general-block-margin * 2;
    }

    &[style="text-align:center"] {
      max-width: 80%;
      margin: $general-block-margin * 2 auto;
      line-height: 1.4;

      @include breakpoint(medium) {
        margin: $general-block-margin * 2.5 auto;
      }
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  p:last-of-type + .accordion--wrapper {
    margin-top: $general-block-margin;
  }

  //.page--home & {
  //  @include breakpoint(medium) {
  //    padding: 0 3rem;
  //  }
  //}
}

//.plugin--padding {
//  .page--home & {
//    @include breakpoint(medium) {
//      padding: 0 3rem;
//    }
//  }
//}

.text--white {
  color: $white;
}

.text--grey {
  color: $dark-gray;
}

.text--margin {
  margin-bottom: $general-margin;
}

.text--oversized {
  font-size: 80px;
}

.text--large {
  line-height: normal;
  font-size: $body-font-medium;

  @include breakpoint(medium) {
    font-size: $body-font-size-large;
  }
}
.text--medium {
  font-size: $body-font-medium;
}

.text--regular {
  font-weight: $regular;
}

.text--small {
  font-size: $body-font-size-small;
}

.text--tiny {
  font-size: $body-font-size-tiny;
}

.text--ultra-large {
  font-size: rem-calc(120px) * 0.6;
  @include breakpoint(medium) {
    font-size: rem-calc(120px) * 0.75;
  }
  @include breakpoint(large) {
    font-size: rem-calc(120px);
  }
}

.text--right {
  //text-align: right;
}

.font--main {
  font-family: $font-main;
}

.text--bold {
  font-weight: $bold;
}

.text--semibold {
  font-weight: $semi-bold;
}

.text--yellow {
  color: $manz;
}

.text--yellow-darken {
  color: $wattle;
}

.text--uppercase {
  text-transform: uppercase;
}
.text--condensed {
  font-family: $font-condensed;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.back-link {
  a img {
    transform: rotate(180deg);
  }

  margin-bottom: $general-block-margin;
}

.text--marked {
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0.1rem 0.5rem;
  line-height: 1.6;
  background: $manz;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    $manz 60%,
    $manz 100%
  );
  font-family: $font-condensed;
}

.text--highlight {
  color: $mahogany;
}

p {
  @include breakpoint(large) {
    font-size: 1.33rem;
  }
}

;@import "sass-embedded-legacy-load-done:117";