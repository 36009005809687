@use "sass:math";

%transition-ease {
  transition: 0.2s ease;
}

html {
}

body {
  display: flex;
  flex-direction: column;
  // I would rather use min-height, but IE11 crashes the party
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  min-height: 100vh;
  position: relative;

  --color-bg: #e5e3df;

  //&:after {
  //  content: '';
  //  background-image: url("img/texture-low.png");
  //  display: block;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  z-index: 10;
  //  opacity: 0.04;
  //  pointer-events: none;
  //}
  //background-color: $white;

  &.nav-is-open {
    //position: fixed;
  }
  -webkit-overflow-scrolling: touch;
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;
  //z-index: 10;
  // Remove this do disable cursor blendmode on background
  background-color: $light-gray;

  //background-color: $light-gray;
  //@include resprop("padding-top", (rem-calc(76px), rem-calc(78px), rem-calc(172px)));

  //padding-top: $general-margin;

  //&.main--invert {
  //  background-color: $white;
  //}
  //
  //.page--home & {
  //  padding-top: 0;
  //  background-color: $white;
  //}
}

// General elements / behavior styling
// ===================================

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.section {
  position: relative;
  padding: 2rem 0;

  // @include breakpoint(medium) {
  //   padding: 4rem 0;
  // }

  &.section--no-paddings {
    padding-bottom: 0;
  }

  &.section--no-moodbaord {
    margin-top: 15vh;
    padding-bottom: 0;
  }

  &.section--full {
    @include breakpoint(medium) {
      padding: 0;
    }

    @include breakpoint(large) {
      padding: 0 10vw;
    }
  }
}

.grid-container {
  [data-appear] & {
    z-index: 1;
  }
  position: relative;
}

@keyframes appear-form {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

.section--form {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 10rem;
  width: 10rem;
  top: 50%;
  left: 50%;
  z-index: 0;
  opacity: 0;
  transform: rotate(0deg) scale(0);
  clip-path: circle(50% at 50% 50%);
  &[data-form="circle"] {
    clip-path: circle(50% at 50% 50%);
  }
  &[data-form="rhombus"] {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  &[data-form="triangle"] {
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);
  }
}

.section--divider {
  content: "";
  position: relative;
  height: 1px;
  background: $medium-gray;
  top: 0;
  display: block;
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  background: $medium-gray;
}

.grid-top-margin {
  margin-top: 6rem;
}

.grid-vh80 {
  @include breakpoint(small) {
    min-height: 100%;
  }
  @include breakpoint(medium) {
    min-height: 80vh;
  }
}

.grid-vh100 {
  @include breakpoint(small) {
    min-height: 100%;
  }
  @include breakpoint(medium) {
    min-height: 100vh;
  }
}

.align-center--small {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.align-center--medium {
  @include breakpoint(medium down) {
    justify-content: center;
  }
}

.grid-container--margin {
  margin-bottom: $general-margin;
  // @include resprop("margin-bottom", ($general-margin, $general-margin * 1.5));
}

.grid-container--margin-half {
  margin-bottom: math.div($general-margin, 3);
  // @include resprop("margin-bottom", ($general-margin / 3, $general-margin / 2));
}

.grid-container--divider {
  border-top: 1px solid $secondary-gray;
}

.breadcrumb__link {
  display: block;
  margin-bottom: $general-block-margin;

  &:before {
    position: relative;
    //content: url("img/icons/arrow-left.svg");
    margin-right: 0.5rem;
  }
}

.show-for-medium-down {
  display: none;
  @include breakpoint(medium down) {
    display: block;
  }
}

.flex-ordering {
  @include breakpoint(small only) {
    .flex-order--1 {
      order: 1;
    }

    .flex-order--2 {
      order: 2;
    }
  }
}

.cell--right {
  @include breakpoint(medium) {
    margin-left: auto;
  }
}

.cell--left {
  @include breakpoint(medium) {
    margin-right: auto;
  }
}

.margin-top {
  margin-top: $general-margin;
}

// 404
.page--notfound {
  position: relative;
  width: 100%;
  height: 100vh;
}
.page--background-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(19, 50, 45, 0.5);
    mix-blend-mode: overlay;
    z-index: 0;
  }
}

.notfound-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  z-index: 100;

  h1 {
    line-height: 0.9;
  }
  span {
    display: block;
    transform: translate(10px, 0);

    @include breakpoint(medium) {
      transform: translate(20px, 0);
    }
    // font-weight: $semi-bold;
    font-weight: normal;
  }
}

.flex-order-1 {
  @include breakpoint(small only) {
    order: 1;
  }
}

.flex-order-2 {
  @include breakpoint(small only) {
    order: 2;
  }
}

.z10 {
  z-index: 10;
  position: relative;
}

.grid-container {
  &.grid-container--flex {
    width: $global-width;
  }
  .html--debug & {
    border: 1px solid $mahogany;
  }
}

//.info {
//  width: 10rem;
//  height: 10rem;
//  position: fixed;
//  left: 0;
//  top: 0;
//  z-index: 10000;
//  background: $astronaut;
//  opacity: 1;
//}

.prevent-click-event {
  user-select: none;
  pointer-events: none;
}

;@import "sass-embedded-legacy-load-done:119";