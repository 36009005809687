.dropdown-list {
  margin: 0;
  padding: 0;
}

.dropdown--item {
  list-style: none;
  position: relative;
}

.dropdown--toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem 0.45rem;
  border: 0.12rem solid $white;
  cursor: pointer;
}

.dropdown--link-name {
  display: block;
  margin-right: 1rem;
  pointer-events: none;
}

.dropdown--children {
  display: none;
  margin: 0;
  padding: 0.75rem 0.75rem 0.75rem;
  position: absolute;
  top: 3rem;
  left: 0;
  background-color: $white;
  width: 100%;
  box-shadow: $box-shadow;

  .toggle--collapsed & {
    display: block;
  }

  &:hover {
    .dropdown--child {
      opacity: 0.6;
    }
  }
}

.dropdown--child {
  list-style: none;
  color: $black;

  &:hover {
    opacity: 1 !important;
  }
}

.dropdown--child-name {
  display: block;
  width: 100%;
  padding: 0.25rem;
}

select {
  padding: 0.5rem 2.5rem 0.7rem 1rem;
  border: none;
  background-color: $light-sea-green;
  color: $white;
  appearance: none;
  cursor: pointer;

  background-image: linear-gradient(45deg, transparent 50%, $white 50%),
    linear-gradient(135deg, $white 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    border: none;
    outline: none;
  }
  &,
  div {
    font: 500 $body-font-size $font-main;
  }
}

.scroll--helper {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.scroll--divider {
  height: 30px;
  width: $global-line-with;
  background-color: $black;
  margin-bottom: 1rem;
}

.download__icon {
  margin-right: 1rem;
  margin-left: 0;
  svg {
    width: 1rem;
    height: 100%;
    transform: translateY(2px);

    @include breakpoint(medium) {
      width: 1.25rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:123";