.newsletter-teaser {
  .separator {
    border: 1px solid $medium-gray;
  }

  h2 {
    font-family: $font-main;
    font-weight: 700;
  }

  .cell > * {
    @include breakpoint(medium) {
      margin-bottom: 2rem;
    }
  }

  p {
    font-weight: 600;
  }

  .button--page-link {
    font-size: unset;
    .button--page-link--icon {
      width: 0.7rem;
    }
    @include breakpoint(medium) {
      margin-top: 4rem;
      font-size: inherit;
      .button--page-link--icon {
        width: inherit;
      }
    }
    width: max-content;
  }
}

;@import "sass-embedded-legacy-load-done:137";