$button-medium: 0.6rem 2rem 0.7rem 2rem;
$button-small: 0.8rem 2rem;
$button-tiny: 0.4rem 2rem;

.button,
input[type="submit"] {
  display: inline-block;
  color: $white;
  background: $black;
  padding: $button-medium;
  text-align: center;
  font-family: $font-main;
  transition: $transition-fast;

  &:hover {
    background-color: lighten($black, 15%);
  }

  &.button--large {
    font-size: $body-font-size;
  }

  &.button--color-theme {
    @extend %page-theme-color;
    color: $white;
  }

  &.button--waring {
    color: $white;
    background: $mahogany;

    &:hover {
      background-color: lighten($mahogany, 15%);
    }
  }

  &.button--download {
    background: none;
    color: $black;
    text-align: left;
    margin-left: 0;
    padding: 0;
    margin-bottom: $general-block-margin;
    // font-weight: $bold;
    font-weight: normal;
    display: flex;
    span {
      display: block;
    }
  }

  &.button--share {
    padding: $button-tiny;
    width: 18rem;
  }

  &.button--outline {
    background: none;
    border: 2px solid $white;
    color: $white;
    position: relative;

    &:after {
      transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform-origin: 100% 50%;
      transform: scaleX(0);
      background-color: $white;
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      left: 0;
      bottom: 0;
    }

    span {
    }

    &:hover {
      span {
        position: relative;
        z-index: 1;
        color: $black;
      }
      &:after {
        transform: scaleX(1);
        transform-origin: 0 50%;
      }
    }
  }

  &.button--flex {
    display: flex;
    align-items: center;
  }
  &.button--testimonial {
    padding: $button-medium;

    @include breakpoint(medium up) {
      margin-bottom: 2rem;
    }
  }

  &.button--nobreak {
    padding: 0.8rem 1.8rem;
    width: 100%;
  }
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button__block {
  margin: $general-block-margin 0 $general-block-margin * 1.5 0;

  @include breakpoint(medium) {
    margin: $general-margin * 0.5 0 $general-margin 0;
  }
}

.button__block--center {
  width: 100%;
  button {
    margin: 0 auto;
    display: block;
  }
}

.page-link--links {
  display: flex;
  flex-wrap: wrap;
}

.page-link--items {
}

.link {
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 0.75rem;
  background: none;
  color: $white;
  font-family: $font-main;
  text-decoration: none !important;
  width: fit-content;

  &.disabled {
    pointer-events: none;
    opacity: 0;
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    content: "";
  }

  &:before {
    background-color: $black;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: 100% 50%;
    transform: scaleX(0);
    z-index: 1;
  }

  &:after {
    transition:
      transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
      opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
    opacity: 1;
    transform: translateX(0) scaleX(1);
    background-color: $white;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 50%;
    }
  }
}

.button--page-link {
  display: flex;
  position: relative;
  font-size: $body-font-size-large;
  margin-right: $general-margin;
  margin-bottom: $general-margin;
  padding-bottom: 1rem;

  &.button--page-link--small {
    font-size: $body-font-size;
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    content: "";
  }

  &:before {
    background-color: $black;
    transition:
      transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
      -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: 100% 50%;
    transform: scaleX(0);
    z-index: 1;
  }

  &:after {
    transition:
      transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
      opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s,
      -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    opacity: 1;
    transform: translateX(0) scaleX(1);
    background-color: $medium-gray;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 50%;
    }
  }
}

.button--page-link--icon {
  display: block;
  width: 1rem;
  margin-left: 2rem;
  transform: translateY(2px);

  &.icon--left {
    margin-left: 0;
    margin-right: 2rem;
  }

  .button--page-link--small & {
    width: 0.75rem;
    transform: translateY(4px);

    svg {
      height: 14px;
      width: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:118";