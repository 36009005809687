$hover--opacity: 0.6;
$fade-out: cubic-bezier(0.41, 0.29, 0.18, 0.97);
body {
  &[data-overlay="true"] {
    overflow: hidden;

    @include breakpoint(medium) {
      padding-right: 15px;
    }
  }
}

.overlay {
  position: fixed;
  //display: none;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: z-index("overlay");
  pointer-events: none;

  &:before {
    content: "";
    background: #eeeeeeb3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 600ms $fade-out;

    [data-overlay="true"] & {
      opacity: 1;
    }
  }

  [data-overlay="true"] & {
    pointer-events: all;
  }
}

.overlay__content {
  position: fixed;
  background-color: $astronaut;
  width: 100%;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
  // height: 100vh;
  overflow: auto;
  height: 100%;

  @include breakpoint(medium) {
    width: 800px;
  }

  @include breakpoint(large) {
    width: 1200px;
  }

  [data-overlay="true"] & {
    transition: transform 0.8s cubic-bezier(0, 0, 0.175, 1);
  }

  [data-overlay-level="first"] & {
    transform: translateX(0);
  }
  [data-overlay-level="second"] & {
    transform: translateX(0);

    @include breakpoint(medium) {
      transform: translateX(20%);
    }

    @include breakpoint(large) {
      transform: translateX(40%);
    }
  }
}

@keyframes change-form {
  0% {
    clip-path: circle(50% at 50% 50%);
  }

  33% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }

  66% {
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);
  }
}

//@keyframes change-form {
//  //0% {
//  //  //clip-path: circle(50% at 50% 50%);
//  //
//  //}
//
//  0% {
//    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
//  }
//  25% {
//    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
//  }
//  30% {
//    clip-path: polygon(28% 0%, 84% 64%, 3% 75%, 18% 29%);
//  }
//  75% {
//    clip-path: polygon(28% 0%, 84% 64%, 3% 75%, 18% 29%);
//  }
//  80% {
//    //clip-path: polygon(84% 2%, 0 54%, 84% 98%);
//    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
//  }
//  100% {
//    //clip-path: polygon(84% 2%, 0 54%, 84% 98%);
//    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
//  }
//
//}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.help--button {
  --help-height: 5rem;

  @include breakpoint(medium) {
    --help-height: 7.5rem;
    right: 1.5rem;
  }

  position: fixed;
  z-index: 1300;
  right: 1rem;
  bottom: 1rem;

  @include breakpoint(large) {
    transform: translateY(calc((50vh - var(--help-height)) * -1));
    &:hover {
      > .help__trigger {
        transform: scale(1.5) translateX(-15%);
      }
    }
  }

  > .help__trigger {
    transition: transform 0.2s ease-in;
  }

  a {
    display: block;
    text-align: center;
    z-index: 10;
    position: relative;
    @extend .text--medium;
  }

  transition: opacity 0.4s ease-in;
  opacity: 1;
  [data-overlay-level="second"] & {
    opacity: 0;
  }
}

.help__trigger--icon {
  width: auto;
  height: var(--help-height);
  transform: translateX(-3px);
  .icon-background {
    fill: $salmon;
  }
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.5));
}

.page--color-orange .help__trigger--icon .icon-background {
  fill: $light-sea-green;
}

.overlay__content--container {
  position: relative;
  display: flex;
  padding: 100px 20px 0px 20px;
  min-height: 100%;

  @include breakpoint(small only) {
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoint(medium) {
    padding: 140px 90px 0px 50px;
  }

  @include breakpoint(large) {
    padding: 140px 180px 0px 100px;
  }
}

.overlay__content--inner-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-right: 2rem;
  @include breakpoint(medium) {
    width: 30rem;
  }

  &[data-overlay-content-level="first"] {
    display: none;

    [data-overlay-level="first"] & {
      display: flex;
    }
  }

  &[data-overlay-content-level="second"] {
    display: none;

    [data-overlay-level="second"] & {
      display: flex;

      @include breakpoint(medium) {
        width: 30rem;
        overflow-y: auto;
      }
      @include breakpoint(large) {
      }
    }
  }
}

.overlay__change--container {
  position: relative;
  opacity: 0;
  margin-top: 3rem;

  @include breakpoint(medium) {
    position: absolute;
    bottom: 0;
  }

  .button--page-link {
    width: 11rem;
    pointer-events: all;
    user-select: all;
    height: 3rem;
    &:after {
      transition:
        transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
        opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
      transform: translateX(100%) scaleX(0);
    }

    .button--page-link--content {
      display: flex;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      pointer-events: none;
      padding-bottom: 0;

      .button--page-link--text {
        transition:
          transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
          opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
        display: block;
        transform: translateX(60px);
        opacity: 0;
      }

      .button--page-link--icon {
        transition:
          transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
          opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
        transform: translateX(60px);
        opacity: 0;
      }
    }
  }

  [data-overlay="true"] & {
    //animation: 0.8s ease-out 1.3s 1 normal forwards running fadeIn;
    opacity: 1;
    transition-delay: 0.2s;

    .button--page-link {
      .button--page-link--content {
        .button--page-link--text,
        .button--page-link--icon {
          transform: translateX(0);
          opacity: 1;
        }
      }

      &:after {
        transform: translateX(0) scaleX(1);
      }
    }
  }

  /* hide when singele overlay only */
  &.overlay__change--hidden {
    display: none;
  }
}

.overlay__menu {
  [data-overlay-level="second"] & {
    margin: auto 0;
  }
}

.overlay__menu-viewer {
  display: none;
  position: relative;
  width: 30rem;

  @include breakpoint(medium) {
    display: block;
    margin-top: $general-margin;
  }

  [data-overlay-level="second"] & {
    display: none;
  }

  /* hide when sinle overlay only */
  .overlay__content--single ~ & {
    display: none;
  }
}

.overlay--navigation {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
  width: 100%;
  @include breakpoint(medium) {
    //width: auto;
  }
}

.overlay__menu--container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.active--token {
    .navigation--item {
      opacity: $hover--opacity;
    }
  }

  &:hover {
    .navigation--item {
      opacity: $hover--opacity;
    }
  }

  @include breakpoint(medium) {
    margin-top: $general-margin;
  }
}

.navigation--item {
  list-style: none;
  margin-bottom: $general-block-margin;
  position: relative;

  [data-overlay-level="second"] & {
    margin-bottom: $general-margin * 0.5;
  }

  &.active,
  &:hover {
    opacity: 1 !important;
  }

  &[data-overlay-accordion="true"] {
    border-bottom: 1px solid $white6;
    padding-bottom: $general-block-margin;
    transition: all 300ms ease;

    &.active {
      padding-bottom: 0;
    }
  }
}

.navigation--item-link {
  display: block;
  padding: 0.25rem 0;
  margin: 0;
  cursor: pointer;

  [data-overlay-accordion="true"] & {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  .overlay--navigation--sitebar & {
    //text-align: right;
  }
}

.navigation--trigger-icon {
  position: relative;
  display: none;
  align-items: center;
  background-color: #0000;
  transition:
    background-color 0.3s,
    border 0.3s;
  text-decoration: none;
  margin: 0;

  [data-overlay-accordion="true"] &,
  .accordion--headline-wrapper &,
  .dropdown--toggle & {
    display: flex;
    height: 100%;
    width: 2rem;
  }

  &:after,
  &:before {
    content: "";
    background-color: $white;
    height: 1px;
    width: 29px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease;
  }

  &.trigger-icon--black {
    &:after,
    &:before {
      background-color: $dark-gray;
    }
  }

  &.icon--small {
    width: 1rem;

    &:after,
    &:before {
      width: 16px;
      height: 0.12rem;
    }
  }

  &:before {
    transform: rotate(180deg);
  }
  &:after {
    transform: rotate(90deg);
  }

  .active &,
  .toggled &,
  .toggle--collapsed & {
    //&:after,
    //&:before {
    //  background-color: $white;
    //}

    &:before {
      transform: rotate(0deg);
    }
    &:after {
      transform: rotate(0deg);
    }
  }
}

.navigation--children {
  position: absolute;
  top: 0;
  opacity: 0;
  counter-reset: list;
  margin-left: 0;
  padding: 0;
  width: 100%;
  pointer-events: none;
  margin-bottom: 0;
  transition:
    max-height 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.25s,
    margin 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.25s,
    transform 0.35s,
    opacity 0.35s;

  [data-overlay-level="second"] & {
    pointer-events: all;
    position: relative;
    opacity: 1;
  }

  .overlay--navigation--sitebar & {
    //text-align: right;
  }

  &:hover {
    .navigation--child-item {
      opacity: $hover--opacity;
    }
  }

  [data-overlay-accordion="true"] & {
    position: relative;

    visibility: hidden;
    overflow: hidden;

    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    transition:
      max-height 0.35s,
      margin 0.35s,
      transform 0.35s 0.25s,
      opacity 0.35s 0.25s;
    margin-bottom: $general-block-margin;

    [data-overlay-accordion="true"] & {
      max-height: 800px;
      transform: translate(0, 0);
      opacity: 1;
      visibility: visible;
      margin-top: $general-block-margin;
      //margin-bottom: $general-block-margin;

      [data-overlay-level="second"] & {
        margin-bottom: $general-block-margin;
      }

      .navigation--child-item:last-child {
        border-bottom: none;
      }
    }
  }

  //display: none;
  //
  //.overlay__menu-viewer & {
  //  display: block;
  //}
}

.navigation--child-item {
  display: flex;
  border-bottom: 1px solid $white6;
  line-height: normal;
  margin-bottom: 0 !important;
  list-style: none;

  &:hover {
    opacity: 1 !important;
  }

  &:last-child {
    //border-bottom: 2px solid $white6;
  }
  &::before {
    //counter-increment: list;
    //content: counter(list, decimal-leading-zero);
    display: block;
    //margin-right: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.navigation--child-item-link {
  padding: 0.8rem 0 0.9rem;
}

@keyframes change-form-mobile {
  0% {
    clip-path: circle(50% at 50% 50%);
    width: 70px;
    height: 70px;
    background-position-x: center;
  }

  32.999% {
    clip-path: circle(50% at 50% 50%);
    width: 70px;
    height: 70px;
    background-position-x: center;
  }

  33% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    width: 80px;
    height: 80px;
    background-position-x: center;
  }

  65.999% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    width: 80px;
    height: 80px;
    background-position-x: center;
  }

  66% {
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);
    width: 82px;
    height: 82px;
    background-position-x: calc(50% + 6px);
  }

  100% {
    clip-path: polygon(84% 2%, 0 54%, 84% 98%);
    width: 82px;
    height: 82px;
    background-position-x: calc(50% + 6px);
  }
}

;@import "sass-embedded-legacy-load-done:126";