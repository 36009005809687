.counselling-teasers--wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.counselling-teasers--hover {
  transition:
    left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.15s,
    opacity 0.2s ease;

  @extend %page-theme-color;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  [data-active="1"] & {
    top: 0;
    width: 100%;
    height: 50%;

    @include breakpoint(medium) {
      left: 0;
      width: 50%;
      height: 100%;
    }
  }
  [data-active="2"] & {
    top: 50%;
    width: 100%;
    height: 50%;

    @include breakpoint(medium) {
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
    }
  }
}

.counselling-teaser {
  flex: 1 1 50%;
  padding: $general-block-margin;
  background-color: $white;

  @include breakpoint(medium) {
    padding: $general-block-margin * 3.5 $general-block-margin * 2.5 2rem;
  }

  &.counselling-teaser--right {
    @include breakpoint(small only) {
      background-color: $light-sea-green;
    }
  }
}

.counselling-teaser--content {
  width: 90%;
  margin-bottom: 2rem;
}

.counselling-teaser--inner-wrapper {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: color 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;

  .counselling-teaser--left & {
    [data-active="1"] & {
      color: $white;
    }
  }

  .counselling-teaser--right & {
    [data-active="2"] & {
      color: $white;
    }
  }
}

.counselling-teaser--cta {
  display: flex;
  justify-content: flex-end;
}

.counselling-teaser--cta-image-wrapper {
  img {
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
    display: block;
    max-width: 100%;
    filter: invert(0.8);
  }
  &.image-phone > img {
    height: 2.5rem;
    [data-active="1"] & {
      filter: invert(0);
    }
  }
  &.image-online > img {
    height: 2rem;
    [data-active="2"] & {
      filter: invert(0);
    }
  }
}

.counselling-teaser--phone {
  font-size: 2rem;
  margin-bottom: 0;
}

.counselling-teaser--location {
  text-align: center;
}

;@import "sass-embedded-legacy-load-done:132";