$black: #202020;
$white: #ffffff;

// main colors

$racing-green: #13322d;
$deep-teal: #17403b;
$viridian: #3d8062;
$atlantis: #8bc147;
$conifer: #b7d253;
$manz: #e5eb50;
$wattle: #d1d64b;

$green: $atlantis;

$salmon: #fa8c74;
$light-sea-green: #33ae9f;
$mahogany: #ba3537;
$astronaut: #45507c;

$salmon20: rgba(250, 140, 116, 0.2);
$light-sea-green20: rgba(250, 140, 116, 0.2);
$mahogany20: rgba(250, 140, 116, 0.2);
$astronaut20: rgba(69, 80, 124, 0.2);

$light-gray: #eeeeee;
$medium-gray: #cbcbcb;
$dark-gray: #9c9c9c;
$menu-color: $dark-gray;

$primary: $astronaut;
$secondary: $mahogany;
$headings-color: $black;

$primary-gray: $light-gray;
$secondary-gray: $medium-gray;

$box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.6);

$white6: rgba(255, 255, 255, 0.6);

$deep-teal-rbga: rgba(19, 50, 45, 0.7);

// gradients

%page-teaser-gradient {
  position: relative;
  background-color: $deep-teal;

  &:before {
    content: "";
    background: transparent linear-gradient(0deg, #13575703 0%, #12304a5c 100%)
      0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;

    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    opacity: 0.8;
  }

  &:after {
    content: "";
    background: transparent
      linear-gradient(180deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
      padding-box;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
  }
}

$page-header-gradient: transparent
  linear-gradient(0deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
  padding-box;

%page-theme-color {
  .page--color-orange & {
    background-color: $salmon;
  }

  .page--color-petrol & {
    background-color: $light-sea-green;
  }

  .page--color-blue & {
    background-color: $astronaut;
  }
}

%page-theme-color-20 {
  .page--color-orange & {
    background-color: $salmon20;
  }

  .page--color-petrol & {
    background-color: $light-sea-green20;
  }

  .page--color-blue & {
    background-color: $astronaut20;
  }
}

.f3cc {
  --f3cc-background: #{$astronaut};
  --f3cc-foreground: #{$white};
  --f3cc-accept-background: #{$secondary};
  --f3cc-accept-foreground: #{$white};
  --f3cc-button-background: #{$secondary-gray};
  --f3cc-button-foreground: #{$black};

  .f3cc-button.modify {
    z-index: 900 !important;
    @include breakpoint(small only) {
      left: 1rem;
      right: auto !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:114";